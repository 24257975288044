import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Employee = ({ img, name, children }) => (
  <div className="w-full sm:w-6/12 lg:w-6/12 mb-8 sm:px-4">
    <div className="relative pb-full mb-3">
      <img
        src={img || "https://placehold.it/400/FFFFFF/4299e1"}
        alt={name}
        className="absolute h-full w-full object-cover rounded-lg"
      />
    </div>
    <h3 className="text-xl text-gray-800">{name}</h3>
    <div className="text-gray-500 text-sm">{children}</div>
  </div>
)

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <section className="relative bg-white overflow-hidden">
      <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
        <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="md:max-w-2xl md:mx-auto lg:col-span-6">
              <h2 className="mt-1 text-4xl tracking-tight leading-10 font-medium text-gray-900 sm:leading-none sm:text-3xl lg:text-4xl xl:text-5xl">
                Dr. Ian S. Davis
              </h2>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                Dr. Ian Davis received his Doctor of Chiropractic degree from
                Palmer College of Chiropractic West in San Jose, California.
                Prior to studying chiropractic he obtained his undergraduate
                degree from the University of Colorado at Boulder. It was his
                studies in Kinesiology and Applied Physiology, the study of
                human movement and performance, that inspired him to appreciate
                chiropractic and to pursue a career in it.
              </p>
              <p className="mt-3 text-base text-gray-500 sm:mt-5">
                He has always been fascinated with the function of the human
                body. As a student he shadowed many different physicians from
                allergists to dermatologists to pediatricians. On the suggestion
                of his father he shadowed multiple chiropractors and started
                getting adjusted. To his surprise he found the adjustments
                relieved the headaches that he thought were “normal” from long
                hours of studying and had been suffering from since high school!
              </p>
              <p className="mt-3 text-base text-gray-500 sm:mt-5">
                As an avid skier/snowboarder, golfer, hockey and soccer player
                he has always been interested in maximizing the performance of
                the human body. As he learned more about how chiropractic can
                help improve athletic performance, prevent injuries, and speed
                and improve healing; he was inspired to go to school to study
                chiropractic.
              </p>
              <p className="mt-3 text-base text-gray-500 sm:mt-5">
                While studying chiropractic he began to realize the significance
                of the structure of the spine and the effect that day to day
                stress can have on it. This motivated him to incorporate
                structural and postural rehabilitation techniques as an integral
                part of his practice. Dr. Ian has been the owner and sole doctor
                at White Center Chiropractic since June of 2006. White Center
                Chiropractic was previously run by Dr. Lisa Jackson and has now
                been serving the White Center, West Seattle, South Park and
                Burien communities for over 30 years.
              </p>
              <p className="mt-3 text-base text-gray-800 font-bold sm:mt-5">
                Se Habla Español.
              </p>
            </div>
            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                <img
                  className="w-full rounded-lg"
                  src="/ian_davis.jpg"
                  alt="Dr. Ian S. Davis"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="bg-gray-50 text-gray-700 py-4 sm:py-10">
      <div className="container mx-auto px-4">
        <h3 className="text-2xl sm:text-3xl text-center mb-4">
          Meet the staff
        </h3>
        <div className="flex flex-wrap justify-center lg:w-8/12 mx-auto">
          <Employee img="/araceli_tovar.jpg" name="Araceli Tovar">
            Front Office Coordinator and X-Ray Technician Araceli joined our
            team in 2009. She is our Front Office Coordinator and X-Ray
            Technician. She was born in Seattle and is currently attending UW
            and getting he BA in Social welfare. She will greet you with a smile
            when you come in.
            <div className="font-bold mt-2">Se Habla Español</div>
          </Employee>
          <Employee img="/caren_davis.jpg" name="Caren Davis">
            She is the Office Manager at White Center Chiropractic. Caren was
            born and raised in Fribourg, Switzerland.
            <div className="font-bold mt-2">
              She is fluent in French and German
            </div>
          </Employee>
          <Employee img="/betty_lewis.jpg" name="Betty Lewis, LMP">
            Betty Lewis graduated from Brian Utting School of Massage in 2004.
            Betty loves working with people. It gives her a great sense of
            accomplishment when she can help someone out of pain or make them
            feel better. It is her goal that they leave the office in a better
            place of mind. Her strength is deep tissue massage. She also enjoys
            learning new methods and techniques to add to her skill set. During
            her time off, Betty loves to garden, bake, travel, and spend time
            with friends and her dog.
          </Employee>
          <Employee
            img="/torito_keoni_le_bord.jpg"
            name="Torito Keoni Le Bord, LMP"
          >
            Torito is originally from California and Hawaii. He has been doing
            massage for 20 years. He specializes in deep tissue, pregnancy,
            injury treatment, sports massage, Reiki and energy work. He loves
            what he does and is passionate about constantly learning ways to
            help people heal their bodies and mind.
          </Employee>
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutPage
